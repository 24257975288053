import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Snackbar, Alert, Box, TextField, Button, IconButton, Tooltip,
    Dialog, DialogActions, DialogContent, DialogTitle, Select, FormControl, InputLabel, MenuItem} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { BlockBlobClient } from '@azure/storage-blob';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const LessonRegisterPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [lesson, setLesson] = useState({
        title: "",
        description: "",
        courseId: "",
        thumbnailUrl: "",
        videoUrl: "",
        resourcesJson: [],
    });

    const [availableCourses, setAvailableCourses] = useState([]);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [newResourceFiles, setNewResourceFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [progressModalOpen, setProgressModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/courses`);
                setAvailableCourses(response.data); 
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };
    
        fetchCourses();
    }, []);
    

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLesson({ ...lesson, [name]: value });
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (!file) return;

        if (type === 'thumbnail') {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => setThumbnailUrl(reader.result);
            reader.readAsDataURL(file);
        } else if (type === 'video') {
            setFile(file);
        } else if (type === 'resource') {
            setNewResourceFiles((prevFiles) => [...prevFiles, file]);
        }
    };

    const handleRemoveNewResource = (index) => {
        setNewResourceFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
        );
    };

    const uploadFileToAzure = async (file, type) => {
        const response = await fetch(`${API_BASE_URL}/api/upload/generate-sas-token?fileName=${file.name}`);
        const { sasUrl } = await response.json();

        const blobClient = new BlockBlobClient(sasUrl);

        try {
            await blobClient.uploadBrowserData(file, {
                blobHTTPHeaders: { blobContentType: file.type },
                onProgress: (progress) => {
                    const percentage = Math.round((progress.loadedBytes / file.size) * 100);
                    setUploadProgress(percentage);
                },
            });

            return sasUrl.split('?')[0];
        } catch (error) {
            console.error(`Error uploading ${type}:`, error);
            throw new Error(`Failed to upload ${type}`);
        }
    };

    const handleSave = async () => {
        // Check if there are any files to upload
        const hasFilesToUpload = selectedFile || file || newResourceFiles.length > 0;

        if (hasFilesToUpload) {
            setProgressModalOpen(true);
        }

        try {
            let thumbnailUrl = null;
            let videoUrl = null;

            if (selectedFile) {
                const thumbnailResponse = await uploadFileToAzure(selectedFile, 'thumbnail');
                thumbnailUrl = thumbnailResponse;
            }

            if (file) {
                const videoResponse = await uploadFileToAzure(file, 'video');
                videoUrl = videoResponse;
            }

            const resourceUrls = [];
            for (const file of newResourceFiles) {
                const resourceUrl = await uploadFileToAzure(file, 'resource');
                resourceUrls.push({ name: file.name, url: resourceUrl });
            }

            const formData = new FormData();
            formData.append('title', lesson.title);
            formData.append('description', lesson.description || '');
            formData.append('courseId', lesson.courseId);
            if (thumbnailUrl) formData.append('thumbnailUrl', thumbnailUrl);
            if (videoUrl) formData.append('videoFileUrl', videoUrl);
            formData.append('resourcesJson', JSON.stringify(resourceUrls));

            await axios.post(`${API_BASE_URL}/api/lessons/lesson/create`, formData, {
                headers: { "Content-Type": "application/json"  },
            });

            setProgressModalOpen(false);
            navigate('/lessons');
        } catch (err) {
            console.error('Error saving lesson:', err);
            setError('Failed to register lesson. Please try again.');
            setSnackbarOpen(true);
            setProgressModalOpen(false);
        }
    };

    const handleCancel = () => {
        navigate('/lessons');
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            <Typography variant="h2" gutterBottom>
                Create a new lesson
            </Typography>

            <Box
                sx={{
                    mt: 4,
                    p: 6,
                    boxShadow: 3,
                    borderRadius: 2,
                }}
            >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        name="title"
                        label="Title"
                        value={lesson.title || ''}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        sx={{ marginBottom: '20px' }}
                    />
                </Box>

                {/* <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                    <TextField
                        label="Description"
                        name="description"
                        value={lesson.description || ''}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        multiline
                        rows={5}
                        sx={{ marginBottom: '20px' }}
                    />
                </Box> */}

                {/* WYSIWYG Editor for Description */}
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 8 }}>
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    Description
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    value={lesson.description || ''}
                    onChange={(value) => setLesson({ ...lesson, description: value })}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'], 
                        [{ list: 'ordered' }, { list: 'bullet' }], 
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], 
                        ['link'],
                      ],
                    }}
                    style={{ height: '200px', marginBottom: '20px' }}
                  />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                    <FormControl fullWidth>
                        <InputLabel id="course-select-label" required>Select a Course</InputLabel>
                        <Select
                            labelId="course-select-label"
                            id="course-select"
                            name="courseId"
                            value={lesson.courseId}
                            onChange={handleInputChange}
                            label="Select a Course"
                            style={{height: '40px'}}
                        >
                            <MenuItem value="">
                                <em>Select a Course</em>
                            </MenuItem>
                            {availableCourses.map((course) => (
                                <MenuItem key={course.id} value={course.id}>
                                    {course.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>


                <Typography 
                    variant="h6" 
                    sx={{ marginBottom: 2, color: 'primary.main' }}>
                        Thumbnail
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '30px' }}>
                    {thumbnailUrl && (
                        <Box
                            component="img"
                            src={thumbnailUrl}
                            alt={lesson.title}
                            sx={{ width: '50%', height: 'auto', borderRadius: 2 }}
                            style={{ border: '2px solid #189AB4' }}
                        />
                    )}
                    <Tooltip title="Upload a thumbnail">
                        <IconButton component="label" style={{ color: '#189AB4' }}>
                            <EditIcon sx={{ fontSize: 30 }} />
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, 'thumbnail')}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Typography 
                    variant="h6" 
                    sx={{ marginBottom: 2, color: 'primary.main' }}
                >
                    Preview Video
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '30px' }}>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleFileChange(e, 'video')}
                        style={{ display: 'none' }}
                        id="video-file"
                    />
                    <label htmlFor="video-file">
                        <Button variant="contained" color="primary" component="span" sx={{ color: '#fff' }} startIcon={<AddCircleOutlineOutlinedIcon />}>
                            Upload Video
                        </Button>
                    </label>
                </Box>

                <Typography 
                    variant="h6" 
                    sx={{ marginBottom: 2, color: 'primary.main' }}
                >
                    Resources
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <input
                        type="file"
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
                        onChange={(e) => handleFileChange(e, 'resource')}
                        style={{ display: 'none' }}
                        id="resource-file"
                    />
                    <label htmlFor="resource-file">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            component="span"
                            style={{ color: 'white' }}
                        >
                            Upload file
                        </Button>
                    </label>

                    {newResourceFiles.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            {newResourceFiles.map((file, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 1,
                                    }}
                                >
                                    <Typography>{file.name}</Typography>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleRemoveNewResource(index)}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>

                <Dialog open={progressModalOpen} onClose={() => setProgressModalOpen(false)}>
                    <DialogTitle>Uploading Files</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" align="center" gutterBottom>
                            Uploading... {uploadProgress}%
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <CircularProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        fullWidth
                        style={{ color: 'white' }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LessonRegisterPage;