import React, { useState } from 'react';
import axios from 'axios';
import { Container, Box, TextField, Button, Typography } from '@mui/material';

const ContactPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    state: '',
    country: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${API_BASE_URL}/api/contact/submit`, formData, {
            headers: { 'Content-Type': 'application/json' },
        });
        alert('Form submitted successfully.');
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            state: '',
            country: '',
        });
    } catch (error) {
        console.error('Error submitting form:', error.response?.data || error.message);
        alert('Failed to submit the form. Please try again.');
    }
  };

  return (
    <Container sx={{ maxWidth: '800px !important', backgroundColor: "#fff", mt: 8, boxShadow: 3, borderRadius: 2, p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please fill out the form below to get in touch with us.
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
            {/* First Name */}
            <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            />

            {/* Last Name */}
            <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
            {/* Email */}
            <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            />

            {/* Phone */}
            <TextField
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            />
        </Box>

        <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
            {/* County/State */}
            <TextField
                label="County/State"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                fullWidth
                required
                margin="normal"
            />

            {/* Country */}
            <TextField
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                fullWidth
                required
                margin="normal"
            />
        </Box>

        {/* Submit Button */}
        <Button 
            onClick={handleSubmit}
            type="submit"
            variant="contained" 
            color="secondary"  
            fullWidth 
            style={{ 
                backgroundColor: "#0A435E",
                color: "#74E6DC",
            }}
        >
            Submit
        </Button>
      </Box>
    </Container>
  );
};

export default ContactPage;