import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Avatar } from "@mui/material";


const AuthorDetailsPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const { id } = useParams(); // Get author ID from the URL
    const [author, setAuthor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAuthorDetails = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/authors/author/${id}`);
                if (!response.ok) {
                throw new Error("Failed to fetch author details");
                }
                const data = await response.json();
                setAuthor(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAuthorDetails();
    }, [id]);

    if (loading) {
        return (
        <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            }}
        >
            <CircularProgress />
        </Box>
        );
    }

    if (error) {
        return (
        <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            }}
        >
            <Typography variant="h6" color="error">
            {error}
            </Typography>
        </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 1000, margin: "auto", padding: 2 }}>
            {/* Author Name */}
            <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="center"
                gutterBottom
                mt={2}
            >
                {author.name}
            </Typography>

            {/* Author Expertise */}
            <Typography
                variant="h6"
                color="#189AB4"
                textAlign="center"
                gutterBottom
                mb={3}
            >
                {author.expertise}
            </Typography>
            
            {/* Author Picture */}
            <Box
                sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 4,
                }}
            >
                <Box
                    component="img"
                    src={author.pictureUrl}
                    alt={author.name}
                    sx={{
                        width: "100%",
                        maxWidth: 600,
                        height: "auto",
                        borderRadius: 4,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                    }}
                />
            </Box>

            {/* Author Description */}
            <Typography
                variant="body1"
                sx={{
                lineHeight: 1.8,
                textAlign: "justify",
                color: "#555",
                marginTop: 2,
                }}
            >
                {author.description}
            </Typography>
        </Box>
    );
};

export default AuthorDetailsPage;