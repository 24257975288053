/**
 * Utility: Get the duration of a video in seconds
 */
export const getVideoDuration = (videoUrl) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = videoUrl;

    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration); // Returns duration in seconds
    });

    video.addEventListener("error", () => {
      reject("Error loading video metadata");
    });
  });
};

/**
 * Utility: Calculate total course duration from a list of lessons
 */
export const calculateTotalDuration = (lessons) => {
  const totalDurationInSeconds = lessons.reduce((sum, lesson) => {
    const [minutes, seconds] = lesson.duration.split(":").map(Number);
    return sum + minutes * 60 + seconds;
  }, 0);
  return formatDuration(totalDurationInSeconds);
};

/**
 * Utility: Format duration (in seconds) to `mm:ss`
 */
export const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};