import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Typography, Card, CardMedia, CardContent, CircularProgress, Snackbar, Alert, Box, IconButton, Button, AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import 'swiper/css';
import 'swiper/css/navigation'; // For navigation arrows
import 'swiper/css/pagination'; // If you're using pagination


const MembersCoursesPage = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [courses, setCourses] = useState([]);
    const [trendingCourses, setTrendingCourses] = useState([]);
    const [advancedCourses, setAdvancedCourses] = useState([]);
    const [comingSoonCourses, setComingSoonCourses] = useState([]);
    const [authorsAM, setAuthors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const [isBeginning, setIsBeginning] = useState({});
    const [isEnd, setIsEnd] = useState({});
    const swiperRef = useRef(null); // Reference to Swiper instance

    const navigate = useNavigate();
    const theme = useTheme();

    // Custom Left Arrow Component
    const CustomAuthorLeftArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "-50px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8249;
        </Typography>
      </Box>
    );
      
    // Custom Right Arrow Component
    const CustomAuthorRightArrow = ({ onClick }) => (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "-50px",
          transform: "translateY(-110%)",
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "100",
            color: "#0A435E",
          }}
        >
          &#8250;
        </Typography>
      </Box>
    );
      
    const CustomAuthorDots = ({ dots }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          marginTop: "10px", // Add spacing from the slider
        }}
      >
        {dots.map((dot, index) => (
          <Box
            key={index}
            onClick={dot.props.onClick}
            sx={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              backgroundColor: dot.props.className.includes("slick-active")
                ? "#189AB4" // Active dot color
                : "#D9D9D9", // Inactive dot color
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </Box>
    );
      
    {/* Custom Pagination Dots */}
    <div className="custom-pagination" style={{ textAlign: 'center' }}>
      <CustomAuthorDots/>
    </div>

    const authorsCarouselSettings = {
        dots: true, 
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1, 
        responsive: [
          {
            breakpoint: 600, 
            settings: {
              slidesToShow: 3.1, 
              slidesToScroll: 1,
              arrows: false, 
              dots: true, 
            },
          },
          {
            breakpoint: 1024, 
            settings: {
              slidesToShow: 2, 
              slidesToScroll: 1,
              arrows: true, 
              dots: true, 
            },
          },
        ],
        arrows: true, 
        prevArrow: <CustomAuthorLeftArrow />, 
        nextArrow: <CustomAuthorRightArrow />,
        appendDots: (dots) => <CustomAuthorDots dots={dots} />,
    };

    useEffect(() => {
        const loadCourses = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/courses`);
                const allCourses = response.data;
                setCourses(allCourses);
                
                // You can call loadTrendingCourses here, or load all at once
                await loadTrendingCourses(); // To load trending courses separately if needed
                await loadAdvancedCourses();
                await loadComingSoonCourses();
            } catch (err) {
                console.error('Error fetching courses:', err);
                setError('Failed to load courses. Please try again later.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };
    
        const loadTrendingCourses = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/courses/trending`);
                setTrendingCourses(response.data);
            } catch (err) {
                console.error('Error fetching trending courses:', err);
            }
        };

        const loadAdvancedCourses = async () => {
            try{
                const response = await axios.get(`${API_BASE_URL}/api/courses/without-memberships`);
                setAdvancedCourses(response.data);
            }
            catch (err) {
                console.error('Error fetching advanced courses:', err);
            }
        };

        const loadComingSoonCourses = async () => {
            try{
                const response = await axios.get(`${API_BASE_URL}/api/courses/without-videos`);
                setComingSoonCourses(response.data);
            }
            catch (err) {
                console.error('Error fetching coming soon courses:', err);
            }
        };

        const loadAuthors = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`${API_BASE_URL}/api/authors`);
                setAuthors(response.data);
            } catch (err) {
                console.error('Error fetching authors:', err);
                setError('Failed to load authors. Please try again later.');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        };
    
        loadCourses();
        loadAuthors();
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSwiperUpdate = (swiper, category) => {
        // Update swiper reference when swiper is initialized
        swiperRef[category] = swiper;
        
        // Update arrow visibility when slide changes
        setIsBeginning((prev) => ({
            ...prev,
            [`isBeginning_${category}`]: swiper.isBeginning,
        }));
        setIsEnd((prev) => ({
            ...prev,
            [`isEnd_${category}`]: swiper.isEnd,
        }));
    };
    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Organize courses by category
    const categories = {
        Money: courses.filter(course => course.category === 'money'),
        Investments: courses.filter(course => course.category === 'investments'),
        // Add other categories as needed
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            {/* AppBar with category links */}
            <AppBar position="sticky" color="default">
                <Toolbar sx={{ borderBottom: '1px solid #ddd' }}>
                    <Tabs value={activeTab} onChange={handleTabChange} sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Tab label="Your Journey" component={ScrollLink} to="your-journey" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        <Tab label="Trending" component={ScrollLink} to="trending" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        
                        {Object.keys(categories).map((category, index) => (
                            <Tab
                                key={index}
                                label={category}
                                component={ScrollLink}
                                to={category.toLowerCase()}
                                offset={-100}
                                smooth
                                duration={500}
                                spy
                                activeClass="active-tab"
                            />
                        ))}

                        <Tab label="Advanced" component={ScrollLink} to="advanced" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        <Tab label="Coming Soon" component={ScrollLink} to="coming-soon" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                        <Tab label="Authors" component={ScrollLink} to="authors" offset={-100} smooth duration={500} spy activeClass="active-tab" />
                    </Tabs>
                </Toolbar>
            </AppBar>


            {error && (
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}

            {/* Category Sections */}
            <Box sx={{ mt: 4 }}>
                {loading ? (
                    <CircularProgress style={{ color: '#189AB4' }} />
                ) : (
                    <>
                        <Box id="your-journey" sx={{ mt: 6 }}>
                            <Typography variant="h4" gutterBottom>Your Journey</Typography>
                            {/* Add Your Journey content here */}
                        </Box>

                        {trendingCourses?.length > 0 && (
                            <Box id="trending" sx={{ mt: 6 }}>
                                <Typography variant="h4" gutterBottom>
                                Trending
                                </Typography>
                                <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start', // Align items to the left
                                    width: '100%',
                                }}
                                >
                                {trendingCourses.map((course, index) => (
                                    <Box
                                        onClick={() => navigate(`/mycourses/${course.id}`)}
                                        key={course.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 2, // Add spacing between items
                                            marginBottom: 2,
                                            width: '100%',
                                            [theme.breakpoints.up('640')]: {
                                            width: '100%',
                                            },
                                            [theme.breakpoints.up('768')]: {
                                            width: '32%',
                                            },
                                            [theme.breakpoints.up('1024')]: {
                                            width: '32%',
                                            },
                                        }}
                                    >
                                        {/* Rank Number */}
                                        <Typography
                                            variant="h5"
                                            fontWeight="bold"
                                            sx={{ width: 30, color: '#189AB4' }}
                                        >
                                            {index + 1}
                                        </Typography>

                                        {/* Course Image */}
                                        <Box
                                            component="img"
                                            src={course.thumbnailUrl}
                                            alt={course.title}
                                            sx={{
                                                width: { xs: 120, xxl: 200 },
                                                height: "auto",
                                                borderRadius: 1,
                                                mx: 2,
                                                objectFit: 'cover',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                            }}
                                        />

                                        {/* Course Details */}
                                        <Box sx={{ flex: 1, minWidth: 0 }}>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 3, 
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                {course.title}
                                            </Typography>
                                            {course.authors && course.authors.length > 0 ? (
                                                course.authors.map((author) => (
                                                    <Typography
                                                        key={author.id}
                                                        variant="body2"
                                                        sx={{ color: '#189AB4' }}
                                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                                    >
                                                    {author.name}
                                                    </Typography>
                                                ))
                                                ) : (
                                                <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                                </Box>
                            </Box>
                        )}


                        <Box sx={{ mt: { xs: 2, xxl: 6 }, width: '100%' }}>
                            <Typography variant="h3" gutterBottom sx={{color: '#189AB4'}}>Courses by Categories</Typography>
                            Embark on expert-led transformation programs
                        </Box>
                        
                        {Object.keys(categories).map((category, index) => (
                            <Box id={category.toLowerCase()} key={category} sx={{ mt: 2, position: 'relative', width: '100%' }}>
                                
                                <Typography variant="h4" gutterBottom>
                                    {category}
                                </Typography>

                                {/* Left Arrow */}
                                {!isBeginning[`isBeginning_${category}`] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow */}
                                {!isEnd[`isEnd_${category}`] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef[category]?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={25} 
                                    slidesPerView={4} 
                                    breakpoints={{
                                        640: { slidesPerView: 1 }, 
                                        768: { slidesPerView: 2 }, 
                                        1024: { slidesPerView: 4 }, 
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, category)}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, [`isBeginning_${category}`]: false }));
                                        setIsEnd(prev => ({ ...prev, [`isEnd_${category}`]: false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                >
                                    {categories[category].map((course, index) => (
                                        <SwiperSlide 
                                            key={`${course.id}-${index}`} 
                                            onClick={() => navigate(`/mycourses/${course.id}`)}
                                        >
                                            {/* Course Image */}
                                            <Box
                                                component="img"
                                                src={course.thumbnailUrl}
                                                alt={course.title}
                                                sx={{
                                                    width: '100%',
                                                    height: { xs: 100, xxl: 200 },
                                                    objectFit: 'cover',
                                                    borderRadius: 2,
                                                }}
                                            />

                                            {/* Course Title */}
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                {course.title}
                                            </Typography>

                                            {/* Course Author */}
                                            {course.authors && course.authors.length > 0 ? (
                                                course.authors.map((author) => (
                                                    <Typography
                                                        key={author.id}
                                                        variant="body2"
                                                        sx={{ color: '#189AB4' }}
                                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                                        >
                                                    {author.name}
                                                    </Typography>
                                                ))
                                                ) : (
                                                <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        ))}

                        {/* Advanced Courses Carousel */}
                        {advancedCourses?.length > 0 && (
                            <Box id="advanced" sx={{ mt: 6, position: 'relative', width: '100%' }}>
                                <Typography variant="h4" gutterBottom>Advanced courses</Typography>

                                {/* Left Arrow for Advanced */}
                                {!isBeginning['isBeginning_advanced'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {/* Right Arrow for Advanced */}
                                {!isEnd['isEnd_advanced'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '45%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['advanced']?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={25}
                                    slidesPerView={4}
                                    breakpoints={{
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 3 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, 'advanced')}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, 'isEnd_advanced': true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, 'isBeginning_advanced': false }));
                                        setIsEnd(prev => ({ ...prev, 'isEnd_advanced': false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                    ref={(swiper) => swiperRef['advanced'] = swiper}
                                >
                                    {advancedCourses.map((course, index) => (
                                        <SwiperSlide
                                            key={course.id}
                                            onClick={() => navigate(`/mycourses/${course.id}`)}
                                        >
                                            {/* Course Content */}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                {/* Course Image */}
                                                <Box
                                                    component="img"
                                                    src={course.thumbnailUrl}
                                                    alt={course.title}
                                                    sx={{
                                                        width: '100%',
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        borderRadius: 2,
                                                        position: 'relative', // Ensure text can overlay properly
                                                    }}
                                                />
                                                {/* LockOpenIcon in the center of the image */}
                                                <LockIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '47%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '100px !important',   // Adjust icon size
                                                        color: 'rgba(0, 0, 0, 0.5)',  // Adjust color
                                                        zIndex: 5,  // Make sure it sits on top of the image
                                                    }}
                                                />
                                                {/* Course Title */}
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontWeight="bold"
                                                        sx={{
                                                            whiteSpace: 'normal', // Allow text to wrap to next line if necessary
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis', // Apply ellipsis if text is too long
                                                            lineHeight: '1.5', // Adjust line height for better readability
                                                            display: 'block', // Ensure it is a block element for correct sizing
                                                            padding: '0 8px', // Add some padding for better presentation
                                                        }}
                                                    >
                                                        {course.title}
                                                    </Typography>
                                                </Box>
                                                {/* Course Author */}
                                                {course.authors && course.authors.length > 0 ? (
                                                    course.authors.map((author) => (
                                                        <Typography
                                                            key={author.id}
                                                            variant="body2"
                                                            sx={{ color: '#189AB4' }}
                                                            onClick={() => navigate(`/authordetails/${author.id}`)}
                                                        >
                                                        {author.name}
                                                        </Typography>
                                                    ))
                                                    ) : (
                                                    <Typography variant="body2" sx={{ color: '#189AB4' }}>No authors available</Typography>
                                                )}
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        )}

                        {/* Coming Soon Courses Carousel */}
                        {comingSoonCourses?.length > 0 && (
                            <Box id="coming-soon" sx={{ mt: 6, position: 'relative' }}>
                                <Typography variant="h4" gutterBottom>
                                    Coming Soon
                                </Typography>

                                {!isBeginning['isBeginning_comingsoon'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '40%',
                                            left: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['coming-soon']?.slidePrev()}
                                    >
                                        <ArrowBackIos sx={{ fontSize: 20, paddingLeft: '5px' }} />
                                    </IconButton>
                                )}

                                {!isEnd['isEnd_comingsoon'] && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: '40%',
                                            right: -20,
                                            zIndex: 10,
                                            transform: 'translateY(-50%)',
                                            width: 40,
                                            height: 40,
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                        }}
                                        onClick={() => swiperRef['coming-soon']?.slideNext()}
                                    >
                                        <ArrowForwardIos sx={{ fontSize: 20, padding: '2px' }} />
                                    </IconButton>
                                )}

                                <Swiper
                                    spaceBetween={25}
                                    slidesPerView={4}
                                    breakpoints={{
                                        640: { slidesPerView: 2 },
                                        768: { slidesPerView: 3 },
                                        1024: { slidesPerView: 4 },
                                    }}
                                    onSwiper={(swiper) => handleSwiperUpdate(swiper, 'coming-soon')}
                                    onSlideChange={(swiper) => handleSwiperUpdate(swiper, 'coming-soon')}
                                    onReachBeginning={() => setIsBeginning(prev => ({ ...prev, 'isBeginning_comingsoon': true }))}
                                    onReachEnd={() => setIsEnd(prev => ({ ...prev, 'isEnd_comingsoon': true }))}
                                    onFromEdge={() => {
                                        setIsBeginning(prev => ({ ...prev, 'isBeginning_comingsoon': false }));
                                        setIsEnd(prev => ({ ...prev, 'isEnd_comingsoon': false }));
                                    }}
                                    loop={false}
                                    modules={[Navigation]}
                                    ref={(swiper) => (swiperRef['coming-soon'] = swiper)}
                                >
                                    {comingSoonCourses.map((course, index) => (
                                        <SwiperSlide key={course.id}>
                                            {/* Course Content */}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                {/* Course Image */}
                                                <Box
                                                    component="img"
                                                    src={course.thumbnailUrl}
                                                    alt={course.title}
                                                    sx={{
                                                        width: '100%',
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        borderRadius: 2,
                                                    }}
                                                />

                                                {/* Course Details */}
                                                <Box sx={{ width: '100%', textAlign: 'left' }}>
                                                    {/* Course Title */}
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight="bold"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            marginBottom: 1,
                                                        }}
                                                    >
                                                        {course.title}
                                                    </Typography>

                                                    {/* Course Authors */}
                                                    {course.authors && course.authors.length > 0 ? (
                                                        course.authors.map((author) => (
                                                            <Typography
                                                                key={author.id}
                                                                variant="body2"
                                                                sx={{
                                                                    color: '#189AB4',
                                                                    marginBottom: 0.5,
                                                                }}
                                                                onClick={() => navigate(`/authordetails/${author.id}`)}
                                                            >
                                                                {author.name}
                                                            </Typography>
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2" sx={{ color: '#189AB4', marginBottom: 0.5 }}>
                                                            No authors available
                                                        </Typography>
                                                    )}

                                                    {/* Course Description */}
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: '#6c757d',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'normal',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: 'vertical',
                                                        }}
                                                    >
                                                        {course.description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        )}


                        <Box id="authors" sx={{ mt: 6, mb: 3 }}>
                            <Typography variant="h4" gutterBottom>Authors</Typography>
                            <Box width="100%" 
                                sx={{  
                                    paddingLeft: { xs: "10px", xxl: "0px" }, 
                                    margin: '0 auto', 
                                }}
                                >
                                <Slider key={authorsAM.length} {...authorsCarouselSettings}>
                                    {authorsAM.map((author) => (
                                    <Box
                                        key={author.id}
                                        onClick={() => navigate(`/authordetails/${author.id}`)}
                                        sx={{
                                            width: "156px",
                                            borderRadius: 2,
                                            textAlign: 'center',
                                            position: 'relative',
                                            px: "10px",
                                        }}
                                    >
                                        <Box
                                        component="img"
                                        src={author.pictureUrl}
                                        alt={author.name}
                                        sx={{
                                            width: "100%",
                                            height: {xs: 269, xxl: 294},
                                            objectFit: 'cover',
                                            borderRadius: 5
                                        }}
                                        />
                                        <Typography variant="body1"
                                        sx={{
                                            mt: 2,
                                            color: "#0A435E",
                                            textTransform: "none",
                                            fontWeight: "700", 
                                        }}
                                        >
                                        {author.name}
                                        </Typography>
                                        <Typography variant="body2"
                                        sx={{
                                            mt: 1,
                                            color: "#000",
                                            textTransform: "none",
                                            fontWeight: "100", 
                                        }}
                                        >
                                        {author.expertise}
                                        </Typography>
                                    </Box>
                                    ))}
                                </Slider>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default MembersCoursesPage;