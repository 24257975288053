import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, List, ListItem, ListItemText, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";
import { getVideoDuration, formatDuration, calculateTotalDuration } from "../../components/VideoUtils";
import DOMPurify from 'dompurify';

const CoursePage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();

  const [course, setCourse] = useState({
    memberships: [],
    lessons: [],
    authors: [],
    resources: [],
  });
  const [playingPreview, setPlayingPreview] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoProgress, setVideoProgress] = useState({});
  const [completedLessons, setCompletedLessons] = useState(new Set());
  const [totalDuration, setTotalDuration] = useState("");

  // Function to handle video progress
  const handleVideoProgress = (progress) => {
    if (selectedLesson) {
      setVideoProgress((prev) => ({
        ...prev,
        [selectedLesson.id]: progress.playedSeconds,
      }));
    }
  };

  // Function to mark the current lesson as complete
  const handleMarkAsComplete = () => {
    if (selectedLesson) {
      setCompletedLessons((prev) => new Set(prev.add(selectedLesson.id)));
    }
  };

  // Fetch course details and calculate durations
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/courses/course/${id}`);
        const lessonsWithDuration = await Promise.all(
          response.data.lessons.map(async (lesson) => {
            try {
              const duration = await getVideoDuration(lesson.videoUrl);
              return { ...lesson, duration: formatDuration(duration) };
            } catch {
              return { ...lesson, duration: "Unknown" };
            }
          })
        );

        setCourse({ ...response.data, lessons: lessonsWithDuration });
        setTotalDuration(calculateTotalDuration(lessonsWithDuration));
      } catch (err) {
        console.error("Error fetching course:", err);
        setError("Failed to load course details. Please refresh the page.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  const totalLessons = course.lessons.length;
  const viewedLessons = completedLessons.size;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 2,
        gap: 2,
        px: 11
      }}
    >
      {/* Left Section: Video and Details */}
      <Box sx={{ flex: 4, minWidth: "75%" }}>
        {console.log(selectedLesson)}
        {selectedLesson ? (
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" gutterBottom style={{ color: "#0A435E", textAlign: "center" }}>
              {selectedLesson.title}
            </Typography>
            
            <VideoPlayer
              blobUrl={selectedLesson.videoUrl}
              onProgress={handleVideoProgress}
              sx={{
                width: "100%",
                height: "auto",
              }}
            />

            {/* <Typography variant="body1" gutterBottom>
              {selectedLesson.description}
            </Typography> */}

            {/* Render WYSIWYG Description */}
            <Typography
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(selectedLesson.description || ''),
              }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 2, color: "#fff" }}
              >
                Download resouces
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMarkAsComplete}
                sx={{ marginTop: 2, color: "#fff" }}
              >
                {completedLessons.has(selectedLesson.id)
                  ? "Completed"
                  : "Mark as Complete"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              px: 2
            }}
          >
            <Typography variant="h3" fontWeight="bold" gutterBottom style={{ color: "#0A435E", textAlign: "center" }}>
              {course.title}
            </Typography>
            
            <Box
              sx={{
                position: 'relative',
                width: '95%',
                aspectRatio: '16 / 9',
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: "0px 8px 24px -2px rgba(0, 0, 0, 0.5)",
                margin: "0 auto"
              }}
            >
              {/* Thumbnail Image */}
              <Box
                component="img"
                src={course.thumbnailUrl}
                alt={course.title}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 4,
                  display: 'block',
                }}
              />

              {/* Play Button */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '90%',
                  left: '6%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 64,
                  height: 64,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                }}
                onClick={() => setPlayingPreview(true)} // Show the video player on click
              >
                <Box
                  component="span"
                  sx={{
                    width: 0,
                    height: 0,
                    borderLeft: '12px solid white',
                    borderTop: '8px solid transparent',
                    borderBottom: '8px solid transparent',
                  }}
                />
              </Box>
            </Box>

            {/* Video Player */}
            {playingPreview && course.previewVideoUrl && (
              <Box
                sx={{
                  mb: 4,
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '80%',
                  aspectRatio: '16 / 9',
                  borderRadius: 4,
                  overflow: 'hidden',
                  transform: 'translateY(30px)',
                  boxShadow: "0px 8px 24px -2px rgba(0, 0, 0, 0.5)",
                }}
              >
                <video
                  id="preview-video"
                  width="100%"
                  height="auto"
                  controls
                >
                  <source src={course.previewVideoUrl} type="video/mp4" />
                </video>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              {course.authors.map((author) => (
                <Typography
                  variant="h6"
                  mt={4}
                  sx={{ color: "#189AB4" }}
                  key={author.id}
                >
                  {author.name}
                </Typography>
              ))}
              <Typography
                  variant="h6"
                  mt={4}
                >
                  Course duration: <span style={{ color: "#189AB4" }}> {totalDuration} minutes </span>
              </Typography>
            </Box>
            
            <Typography variant="body1" mt={2}>
              {course.description}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Right Section: Lessons */}
      <Box
        sx={{
          flex: 1.2,
          minWidth: "25%",
          backgroundColor: "#f0f0f0",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Lessons
          </Typography>
          <Typography variant="h6" gutterBottom>
            {viewedLessons}/{totalLessons} completed
          </Typography>
        </Box>


        {course.lessons && course.lessons.length > 0 ? (
          <List
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
              padding: 0,
            }}
          >
            {course.lessons.map((lesson, index) => (
              <ListItem
                key={`${lesson.id}-${index}`}
                onClick={() => setSelectedLesson(lesson)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                  borderRadius: 2,
                  py: 1.5,
                  marginBottom: 1.5,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <Box
                  component="img"
                  src={lesson.thumbnailUrl || course.thumbnailUrl}
                  alt={lesson.title}
                  sx={{
                    width: 120,
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: 2,
                    marginRight: 2,
                  }}
                />
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#000",
                      }}
                    >
                      {lesson.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      sx={{ color: "#189AB4" }}
                    >
                      {lesson.duration || "Unknown"} minutes
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="#aaa" mt={2}>
            No lessons available for this course.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CoursePage;
